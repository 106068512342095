<template>
  <div class="bbc">
    <div style="background-color:white" class="d-flex justify-content-between justify-space-between">
      <router-link to="login" style="padding:10px;">
        <img src="../../static/img/logo-full.png" alt="logo" style="margin-left: 25px;" />
      </router-link>
      <!-- <div> -->
      <div class="text-grey-dim font-bold relative help">
        <a class="cursor-pointer hover:text-primary" @click="openDropDown" title="Need Help"
          ><strong> <img src="../../static/img/help.png" style="height:2rem"/></strong
        ></a>
        <HelpDropDown v-if="showDropDown" @close="showDropDown = false" />
      </div>
      <!-- </div> -->
    </div>
    <AuthWrapper>
      <div class="auth-contents" v-if="!formState.send">
        <a-form @submit="handleSubmit" :model="formState" class="white-box" layout="vertical">
          <sdHeading as="h3">Forgot Password? </sdHeading>
          <p class="forgot-text">
            Enter the mobile number associated with your Better Doctor account, we’ll send you a one-time passcode to reset your password.
          </p>
          <a-form-item label="Phone Number" name="Phone">
            <a-input
              type="text"
              pattern="[(]?[0-9]{3}[)][\s]?[0-9]{3}?[-][0-9]*"
              inputmode="numeric"
              v-on:keyup="ValidNumber($event.target)"
              maxlength="14"
              v-model:value="formState.mob"
              placeholder="(315)457-2501"
              required
            />
          </a-form-item>

          <a-form-item>
            <sdButton class="btn-reset" style="background-color: #1baba8" htmlType="submit" type="primary" size="large">
              <!-- {{ formState.passreset ? 'Sending...' : 'Send' }} -->
              <div v-if="!formState.passreset">Send</div>
              <a-spin v-if="formState.passreset" color="white" size="large" />
            </sdButton>
          </a-form-item>
          <a-row>
            <a-col :xl="16">
              <p class="return-text"><router-link to="/auth/login">Return to Sign In</router-link></p>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="auth-contents" v-else>
        <a-form @submit="otpVerification" :model="formState" class="white-box" layout="vertical">
          <sdHeading as="h3">OTP Verification</sdHeading>
          <p class="forgot-text">Enter OTP that is sent to your mobile number.</p>
          <a-form-item label="Otp" name="Phone">
            <a-input type="text" maxlength="4" v-model:value="formState.otp" placeholder="" required />
          </a-form-item>

          <a-form-item>
            <a-row>
              <a-col :xl="16" v-if="!formState.resend">
                <sdButton style="background-color: #1baba8; width: 130px" htmlType="submit" type="primary" size="small">
                  <!-- {{ formState.passreset ? 'Sending...' : 'Send' }} -->
                  <div v-if="!formState.passreset">Verify</div>
                  <a-spin v-if="formState.passreset" color="white" size="large" />
                </sdButton>
              </a-col>
              <a-col :xl="16" v-else>
                <a-row>
                  <a-col :xl="8"><p>Resend OTP</p></a-col>
                  <a-col :xl="4"
                    ><sdFeatherIcons @click="callResend" type="send" size="18" style="color: #108ee9"
                  /></a-col>
                </a-row>
              </a-col>
            </a-row>
          </a-form-item>
          <!-- <a-row>
            <a-col :xl="16">
          
            <sdButton  style="background-color: #1baba8;  width: 100px" @click="resend" htmlType="button"  size="small"> -->
          <!-- {{ formState.passreset ? 'Sending...' : 'Send' }} -->
          <!-- <div v-if="!formState.passreset">Resend</div>
              <a-spin v-if="formState.passreset" color="white" size="large" />
            </sdButton>
            </a-col>
          </a-row> -->
          <a-row>
            <a-col :xl="16">
              <p class="return-text">Return To <router-link to="/auth/login">Sign In</router-link></p>
            </a-col>
            <a-col :xl="8">
              <p class="return-text">Time Left: {{ formState.countDown2 }}</p>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </AuthWrapper>
  </div>
</template>
<script>
import { reactive } from 'vue';
import { AuthWrapper } from './style';
import axios from 'axios';
import { useRouter } from 'vue-router';

import { Notification } from 'ant-design-vue';
import HelpDropDown from './HelpDropDown.vue';

const ForgotPassword = {
  methods: {
    openNotificationIcon() {
      Notification.open({
        message: 'Notification Title',
        description:
          'This is the content of the notification. This is the content of the notification. This is the content of the notification.',
        icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
      });
    },
  },
  name: 'Otp',
  data() {
    return {
      countDown: 10,
      showDropDown: false,
    };
  },
  methods: {
    openDropDown() {
      console.log('-----');
      console.log(this.showDropDown);
      this.showDropDown = true;
      console.log('-----');
    },
    countDownTimer() {
      console.log('timer');
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown -= 1;
          this.countDownTimer();
        }, 1000);
      }
    },
    ValidNumber(e) {
      // console.log(e.value.length)
      if (e.value.length === 1) {
        e.value =  '(' + e.value;
      }
      if (e.value.length === 4) {
        e.value = e.value + ') ';
      }
      if (e.value.length === 9) {
        e.value = e.value + '-';
      }
      // console.log(e.value)
    },
  },
  created() {
    // this.countDownTimer()
  },
  components: { AuthWrapper, HelpDropDown },
  setup() {
    const callResend = values => {
      console.log('function callResend');
      //     if(formState.count >=1){
      //     formState.count = formState.count - 1
      // formState.send = false
      //     }else{
      //           Notification.open({
      //               message: "OTP ",
      //               description: 'No Attempts Left',
      //               icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
      //             });

      //     }
      if (formState.count > 1) {
        formState.count = formState.count - 1;
        handleSubmit();
        formState.resend = false;
      } else {
        Notification.open({
          message: 'OTP',
          description: 'No Attempts Lefts',
          icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
        });
      }
      console.log('function callResend called handle submit');
    };
    const countDownTimer2 = values => {
      if (formState.countDown2 > 0) {
        setTimeout(() => {
          formState.countDown2 -= 1;
          countDownTimer2();
        }, 1000);
      } else {
        Notification.open({
          message: 'OTP',
          description: 'OTP Expired',
          icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
        });

        formState.resend = true;

        // formState.count = formState.count - 1
        // formState.send = false
      }
    };
    const router = useRouter();
    const otpVerification = values => {
      console.log('otpVerifiation');

      formState.passreset = true;
      axios
        // .post('https://api.getbetterdoctor.com/api/OtpValidation', { token: formState.otp })
        .post('post-otpvalidation.php', { token: formState.otp })
        .then(res => {
          let response = res.data;
          console.log(response);
          if (response.status === false) {
            formState.passreset = false;
            Notification.open({
              message: "OTP Doesn't Match",
              description: 'OTP Is Not Valid',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });

            console.log('OTP Is Not Valid');
          }

          if (response.status === true) {
            formState.passreset = false;
            // formState.send=false

            Notification.open({
              message: 'OTP Verification',
              description: 'OTP is valid',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });
            console.log('OTP Valid');
            router.push({ name: 'reset', params: { token: response.token } });
          }
        })
        .catch(err => {
          console.log('err');

          console.log(err);
        });
    };
    const handleSubmit = values => {
      //countDownTimer()
      console.log('in function');
      formState.passreset = true;
      let str = formState.mob;
      let str3 = str.replace(' ', '');
      let str1 = str3.replace('(', '');
      let str2 = str1.replace(')', '');
      let mobile = str2.replace('-', '');
      console.log(mobile);

      axios
        // .post('post-otp.php', { phone: mobile })
        // .post('https://api.getbetterdoctor.com/api/otp', { phone: mobile })
        .post('https://127.0.0.1:8000/api/otp', { phone: formState.mob, mobile: mobile })
        .then(res => {
          let response = res.data;
          console.log(response);

          if (response.status === false) {
            formState.passreset = false;
            Notification.open({
              message: 'Password Reset Fail',
              description: 'Mobile Number Not Found',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });

            console.log('Mobile Not Found');
          }

          if (response.status === true) {
            formState.countDown2 = 60;
            countDownTimer2();
            formState.passreset = false;
            formState.send = true;

            Notification.open({
              message: 'Password Reset',
              description: 'OTP Sent',
              icon: <sdFeatherIcons type="smile" size="14" style="background-color: #1BABA8" />,
            });
            console.log('OTP Sent');
          }
        })
        .catch(err => {
          console.log('err');
          formState.passreset = false;
          Notification.open({
            message: 'Password Reset',
            description: 'Unable to generate OTP',
            icon: <sdFeatherIcons type="smile" size="14" style="background-color: red !important" />,
          });
          console.log(err);
        });

      // // router.push('/');
      //  dispatch('login');
    };

    const formState = reactive({
      mob: '',
      passreset: false,
      send: false,
      otp: '',
      count: 3,
      countDown2: 60,
      resend: false,
    });

    return {
      handleSubmit,
      otpVerification,
      formState,
      countDownTimer2,
      callResend,
    };
  },
};

export default ForgotPassword;
</script>
<style scoped>
.bbc {
  background-image: url('../../static/img/background.png');
  /* background-image: url('https://pngimage.net/wp-content/uploads/2018/06/png-background-images-1-1.png'); */
  height: 100vh;
  /* height: 920px; */
  background-color: #f4f5f7;
  display: block;
}
@media (min-width: 768px) and (max-width: 1200px) {
  /* For desktop: */
  .bbc {
    /* height: 290%; */
    width: 200%;
  }
  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 48px;
    font-weight: 600;
    margin-bottom: 45px;
  }

  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
}
.white-box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.help {
  /* margin-inline: 500px; */
  /* margin-right: 900px; */
  /* float:right;
   padding: 1%; */
  position: relative;
  margin-right: 26px;
  color: gray;
  align-items: center;
  display: flex;
}
.help a,
a:visited,
a:hover {
  color: gray;
}
</style>
<style>
.ant-spin-dot-item {
  background-color: white !important;
}
</style>
