<template>
  <div class="bbc">
    <div
      style="background-color: white"
      class="d-flex justify-content-between justify-space-between"
    >
      <router-link to="login" style="padding: 10px">
        <img src="../../static/img/logo-full.png" alt="logo" style="margin-left: 25px" />
      </router-link>
      <!-- <router-link to="/"> -->
      <!-- <sdFeatherIcons type="home" /> -->
      <!-- <img src="https://migrationade.com/pill.svg" alt="" srcset=""> -->

      <!-- </router-link> -->
      <!-- <div> -->
      <div class="text-grey-dim font-bold relative help">
        <a
          class="cursor-pointer hover:text-primary"
          @click="openDropDown"
          title="Need Help"
          ><strong> <img src="../../static/img/help.png" style="height: 2rem" /></strong
        ></a>
        <HelpDropDown v-if="showDropDown" @close="showDropDown = false" />
      </div>
      <!-- </div> -->
    </div>
    <AuthWrapper>
      <div class="auth-contents">
        <!-- <div style="background-color:white"> -->
        <!-- <div  > -->
        <a-form
          @finish="handleSubmit"
          :model="formState"
          class="white-box"
          layout="vertical"
        >
          <!-- <div class="form"> -->
          <sdHeading as="h3"> Welcome Back </sdHeading>
          <a-form-item name="username" label="">
            <a-input
              type="email"
              v-model:value="formState.email"
              placeholder="Enter your email here..."
              required
            />
          </a-form-item>
          <a-form-item name="password" initialValue="123456">
            <a-input
              type="password"
              minlength="5"
              v-model:value="formState.password"
              placeholder="Enter your password here..."
              required
            />
          </a-form-item>
          <!-- <div class="auth-form-action">
          <a-checkbox @change="onChange">Keep me logged in</a-checkbox>
          <router-link class="forgot-pass-link" to="/auth/forgotPassword">
            Forgot password?
          </router-link>
        </div> -->
          <a-form-item>
            <!-- <sdButton class=" block" htmlType="submit" type="primary" size="large">
            {{ isLoading ? 'Loading...' : 'Sign In' }}
          </sdButton> -->

            <sdButton
              style="background-color: #1baba8"
              size="large"
              htmlType="submit"
              type="primary"
              block
            >
              <!-- {{ formState.login ? 'Signing In...' : 'Sign In' }} -->

              <!-- <a-spin   size='large'/> -->
              <div v-if="!formState.login">Sign In</div>
              <a-spin v-if="formState.login" size="large" />
            </sdButton>
          </a-form-item>

          <p class="return-text" style="width: 100%">
            <span style="float: left; padding-right: 10px"
              ><router-link to="/auth/forgotPassword"
                ><a href="#"> Forgot Your Password?</a></router-link
              ></span
            ><span style="float: left">
              <a
                href="https://getbetterdoctor.com/forms/dist/#/select-gender"
                style="color: #1890ff"
                >Not a customer? Get Started</a
              ></span
            >
          </p>
        </a-form>

        <!-- </div> -->
        <!-- </div> -->
      </div>
    </AuthWrapper>
  </div>
</template>
<script>
import { FacebookOutlined, TwitterOutlined } from "@ant-design/icons-vue";
import { Notification } from "ant-design-vue";
import { computed, reactive, ref } from "vue";
import { useStore } from "vuex";
import { AuthWrapper } from "./style";
import { useRouter, useRoute } from "vue-router";
import { Auth0Lock } from "auth0-lock";
import { auth0options } from "@/config/auth0";
import axios from "axios";
import Cookies from "js-cookie";
import VueCookies from "vue-cookies";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import HelpDropDown from "./HelpDropDown.vue";

const domain = process.env.VUE_APP_AUTH0_DOMAIN;
const clientId = process.env.VUE_APP_AUTH0_CLIENT_ID;

const SignIn = {
  name: "SignIn",
  components: { FacebookOutlined, TwitterOutlined, AuthWrapper, HelpDropDown },
  data() {
    return {
      email: "",
      showDropDown: false,
      // loader: false
    };
  },
  methods: {
    openDropDown() {
      console.log("-----");
      console.log(this.showDropDown);
      this.showDropDown = true;
      console.log("-----");
    },
    openNotificationIcon() {
      Notification.open({
        message: "Notification Title",
        description:
          "This is the content of the notification. This is the content of the notification. This is the content of the notification.",
        icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
      });
    },
  },
  setup() {
    const { state, dispatch } = useStore();
    const isLoading = computed(() => state.auth.loading);

    const checked = ref(null);
    const router = useRouter();
    const values = ref(null);

    const route = useRoute();
    let formState = reactive({
      email: "",
      password: "",
      login: false,
    });
    const handleTokenLogin = (token) => {
      formState.login = true;
      //  axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
      // axios.defaults.headers.common['Access-Control-Allow-Methods'] = '*';
      // axios
      //       .post('https://api.getbetterdoctor.com/api/token', { token: token },{
      //  headers: {
      //          'Access-Control-Allow-Origin' : '*',
      //   'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      //       }
      //     })
      // .post('login-token',{token:token})
      // .post('https://127.0.0.1:8000/api/token',{ token: token})
      // .post('https://127.0.0.1:3000/api/token',{token:token},)
      // .post('login-token',{token:token},{
      //   headers: {
      //     'Access-Control-Allow-Origin' : '*',
      //     'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS'
      //   }
      // })
      axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
      axios.defaults.headers.common["Access-Control-Allow-Methods"] = "*";
      axios
        .post(
          "login-token.php",
          { token: token },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            },
          }
        )
        .then((res) => {
          let response = res.data;
          if (response.status === false) {
            formState.login = false;
            Notification.open({
              message: "Auth Fail",
              description: "Login Credentials Are Faulted",

              icon: (
                <sdFeatherIcons
                  type="smile"
                  size="14"
                  style="background-color: #1BABA8"
                />
              ),
            });
          }
          if (response.status === true) {
            Cookies.set("user", response.item);
            Cookies.set("token", response.token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;

            console.log("cookie set");
            console.log(Cookies.get("token"));

            router.push("/");

            dispatch("login");
          }
        })
        .catch((err) => {
          (this.load = true), console.log("err");
          Notification.open({
            message: "Validation Fails",
            description: err.response.data,
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
          console.log(err);
        });
    };
    if (route.query.token) handleTokenLogin(route.query.token);

    // const handleSubmit = value => {
    //     values.value = value;
    //   console.log('button clicked')
    //   console.log(formState.email)

    //   };

    const handleSubmit = () => {
      // axios.get('https://localhost/api/send-mail',{
      //   headers: { "Access-Control-Allow-Origin": "*" }
      // }).then(res => {console.log(res)});

      formState.login = true;
      var config = {
        headers: {
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip, deflate, br",
          "Access-Control-Allow-Origin": "*",
        },
      };

      axios
        .post(
          "login.php",
          { email: formState.email, password: formState.password },
          config
        )
        // .post('https://127.0.0.1:8000/api/login',{ email: formState.email , password: formState.password})
        // .post('https://api.getbetterdoctor.com/api/login',{ email: formState.email , password: formState.password})
        .then((res) => {
          let response = res.data;
          if (
            response.status === false ||
            response.message == "Password mismatch" ||
            response.message == "User does not exist"
          ) {
            formState.login = false;
            Notification.open({
              message: "Auth Fail",
              description: "Invalid Login Credentials",
              icon: (
                <sdFeatherIcons
                  type="smile"
                  size="14"
                  style="background-color: #1BABA8"
                />
              ),
            });
          }
          if (response.status === true) {
            Cookies.set("user", response.item);
            Cookies.set("token", response.token);
            axios.defaults.headers.common["Authorization"] = "Bearer " + response.token;

            console.log("cookie set");
            console.log(Cookies.get("token"));

            router.push("/");

            dispatch("login");
          }
        })
        .catch((err) => {
          formState.login = false;
          Notification.open({
            message: "Validation Fails",
            description: err.response.data.message,
            icon: <sdFeatherIcons type="smile" size="14" style="color: #108ee9" />,
          });
          console.log(err);
        });
    };
    const onChange = (checked) => {
      checked.value = checked;
    };

    const lock = new Auth0Lock(clientId, domain, auth0options);

    lock.on("authenticated", (authResult) => {
      lock.getUserInfo(authResult.accessToken, (error) => {
        if (error) {
          return;
        }

        handleSubmit();
        lock.hide();
      });
    });

    return {
      isLoading,

      checked,
      handleSubmit,
      onChange,
      formState,
      lock,
    };
  },
};

export default SignIn;
</script>
<style scoped>
.bbc {
  background-image: url("../../static/img/background.png");
  background-color: #f4f5f7;
  display: block;
  height: 100vh;
}

@media (min-width: 668px) and (max-width: 1023px) {
  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 45px;
  }
  /* .label {
    height: auto;
    font-size: 92px;
} */
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
}
@media (min-width: 1024px) and (max-width: 1700px) {
  /* For desktop: */
  .lavNKW .auth-contents form {
    margin-top: 80px;
    width: 599px;
  }
  .lavNKW .auth-contents form h1 {
    font-size: 56px;
    font-weight: 600;
    margin-bottom: 45px;
  }
  /* .label {
    height: auto;
    font-size: 92px;
} */
  .ant-form-vertical .ant-form-item-label > label {
    height: auto;
    font-size: 90px;
  }
  .lavNKW .auth-contents form .ant-input {
    padding: 12px 11px;
    height: 45px;
    width: 599px;
  }
}
.white-box {
  border-radius: 5px;
  background-color: white;
  padding: 20px;
}
.help {
  /* margin-inline: 500px; */
  /* margin-right: 900px; */
  /* float:right;
   padding: 1%; */
  position: relative;
  margin-right: 26px;
  color: gray;
  align-items: center;
  display: flex;
}
.help a,
a:visited,
a:hover {
  color: gray;
}
</style>
<style>
.ant-spin-dot-item {
  background-color: white !important;
}
</style>
